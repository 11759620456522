import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import ImageAvatar from "../images/avatar-2.png"
import IconFacebook from "../images/icons/facebook.svg"
import IconYoutube from "../images/icons/youtube.svg"
import IconInsta from "../images/icons//insta.svg"

import * as indexStyles from "./index.module.css"

const mapNode = list => list.map(({ node }) => node.data)

const IndexPage = ({ data }) => {
  const {
    allPrismicAffiliateProduct: { edges: productEdges },
    allPrismicMediaLink: { edges: mediaEdges },
  } = data
  const products = mapNode(productEdges)
  const mediaLink = mapNode(mediaEdges)

  return (
    <Layout>
      <Seo title="Trang chủ" />
      <div className={indexStyles.avatarContainer}>
        <div className={indexStyles.title}>
          <img className={indexStyles.avatar} src={ImageAvatar} alt="avatar" />
        </div>
        <h1 className={indexStyles.subtitle}>Tương tác với Huy Poster</h1>
        <div className={indexStyles.infoContainer}>
          <div className={indexStyles.infoWrapper}>
            <a
              href="https://www.youtube.com/channel/UCitrOi2Xi4YbsYZ9PUfXuPg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={IconYoutube}
                alt="https://www.youtube.com/channel/UCitrOi2Xi4YbsYZ9PUfXuPg"
                className={indexStyles.socialIcon}
              />
            </a>
            <a
              href="https://www.facebook.com/doanhuyposter"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={IconFacebook}
                alt="https://www.facebook.com/doanhuyposter"
                className={indexStyles.socialIcon}
              />
            </a>
            <a
              href="https://www.instagram.com/doanhuyposter"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={IconInsta}
                alt="https://www.instagram.com/doanhuyposter"
                className={indexStyles.socialIcon}
              />
            </a>
          </div>
        </div>
        <div>
          <h2 className={indexStyles.subtitle}>Đăng kí học Photoshop</h2>
          <ul className={indexStyles.buttonList}>
            {mediaLink.map((media, index) => (
              <li className={indexStyles.buttonItem} key={index}>
                <a
                  href={media.link.url}
                  className={indexStyles.buttonPrimary}
                  target="_blank"
                  rel="noopener noreferer"
                >
                  {media.title.text}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <h2 className={indexStyles.productTitle}>
        Những sản phẩm Huy sử dụng trong Video
      </h2>
      <div className={indexStyles.productContainer}>
        {products.map((product, index) => (
          <React.Fragment key={index}>
            <a
              className={indexStyles.productItemContainer}
              href={product.link.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className={indexStyles.productItemWrapper}>
                <img
                  src={product.thumbnail.url}
                  alt={product.name.text}
                  className={indexStyles.productItemImage}
                />
              </span>
              <h3
                className={indexStyles.productItemTitle}
                dangerouslySetInnerHTML={{ __html: product.name.text }}
              ></h3>
            </a>
          </React.Fragment>
        ))}
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query allPrismicAffiliateProduct {
    allPrismicAffiliateProduct {
      edges {
        node {
          data {
            link {
              url
            }
            name {
              text
            }
            thumbnail {
              url
            }
          }
        }
      }
    }
    allPrismicMediaLink {
      edges {
        node {
          data {
            title {
              text
            }
            link {
              url
            }
          }
        }
      }
    }
  }
`
