// extracted by mini-css-extract-plugin
export var avatarContainer = "index-module--avatarContainer--P0RGM";
export var title = "index-module--title--21mNY";
export var avatar = "index-module--avatar--1TOdV";
export var subtitle = "index-module--subtitle--fCNrr";
export var zoomAnimation = "index-module--zoomAnimation--wC7lk";
export var zoom = "index-module--zoom--3zagO";
export var buttonList = "index-module--buttonList--1MHhR";
export var buttonItem = "index-module--buttonItem--1t8d5";
export var buttonPrimary = "index-module--buttonPrimary--JpUUm";
export var buttonWithZoom = "index-module--buttonWithZoom--1mWTT index-module--buttonPrimary--JpUUm index-module--zoomAnimation--wC7lk";
export var buttonSecondary = "index-module--buttonSecondary--36F7D index-module--buttonPrimary--JpUUm";
export var infoContainer = "index-module--infoContainer--3xwut";
export var infoWrapper = "index-module--infoWrapper--eneCl";
export var socialIcon = "index-module--socialIcon--1Rkyp";
export var productTitle = "index-module--productTitle--2Q7r-";
export var productContainer = "index-module--productContainer--luMSI";
export var productItemContainer = "index-module--productItemContainer--3b0Fi";
export var productItemWrapper = "index-module--productItemWrapper--3ML0q";
export var productItemImage = "index-module--productItemImage--3PCB6";
export var productItemTitle = "index-module--productItemTitle--2FxA5";